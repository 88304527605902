import React from "react";
import { useState } from "react";
import "./Results.css";
import BCA_logo from "../../assets/BCA_logo.png";
import { values } from "./resultsData";
import { resultsData } from "./resultsData";

const [
  wolf,
  orca,
  elk,
  eagle,
  bear,
  lion,
  rabbit,
  bee,
  goose,
  octopus,
  dolphin,
  owl,
] = resultsData;

function Results({ result }) {
  const [continued, setContinued] = useState(false);

  const processResults = () => {
    let valueKeys = Object.keys(result);

    valueKeys.sort(function (a, b) {
      return result[b] - result[a];
    });

    let firstValue = valueKeys[0];
    let secondValue = valueKeys[1];

    // console.log(firstValue + " " + secondValue);

    //for testing
    // return rabbit;

    switch (firstValue) {
      case "here_for":
        switch (secondValue) {
          case "own_our":
            return orca;
          case "one_team":
            return wolf;
          case "learners":
            return elk;

        }

      case "own_our":
        switch (secondValue) {
          case "here_for":
            return lion;
          case "one_team":
            return eagle;
          case "learners":
            return bear;
        }

      case "one_team":
        switch (secondValue) {
          case "here_for":
            return bee;
          case "own_our":
            return goose;
          case "learners":
            return rabbit;
        }

      case "learners":
        switch (secondValue) {
          case "here_for":
            return octopus;
          case "own_our":
            return dolphin;
          case "one_team":
            return owl;
        }
    }
  };

  const [animal, setAnimal] = useState(processResults());

  return (
    <div className="quiz-container">
      <div className={`results-bg-${animal.backgroundName}`}>
        <div className="headers">
          <h1 className="title">BC ASSESSMENT VALUES QUIZ</h1>

          <img src={BCA_logo} className="logo" alt="logo" />
        </div>

        <div className="results-container">
          <div className="top-text">
            {continued ? (
              <p>
                {animal.slogan} <span>You are the</span>
              </p>
            ) : (
              <div>
                <b>Nice Work! </b>
                Your highest score aligns you with the value{" "}
                <p
                  style={{
                    color: ` ${animal.value.borderColor}`,
                    fontWeight: "bold",
                    display: "inline",
                  }}
                >
                  {animal.value.name + ". "}
                </p>
                You are the
              </div>
            )}
          </div>

          <h1
            className="animal-name"
            style={{
              //FIXCIDOSJF
              color: ` ${animal.color}`,
              fontWeight: "500",
              fontStyle: "italic",
              display: "inline",
              fontSize: "2.5rem",
            }}
          >
            {animal.name + "."}
          </h1>

          {/* top box */}

          <div className="results-boxes-container">
            {continued ? (
              <div
                className="transparent-color-box"
                style={{
                  backgroundColor: `${animal.value.backgroundColor}`,
                  borderColor: `${animal.value.borderColor}`,
                }}
              >
                <p
                  style={{
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                >
                  {" "}
                  {animal.value.fullName}
                </p>
                <p>{animal.value.motto}</p>
              </div>
            ) : (
              <div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
              </div>
            )}

            {/* middle boxes */}

            <div
              className="transparent-color-box"
              style={{
                backgroundColor: `${animal.value.backgroundColor}`,
                borderColor: `${animal.value.borderColor}`,
              }}
            >
              <div className="characteristics">
                <div
                  className="section"
                  style={{
                    minWidth: "170px",
                  }}
                >
                  <h3 className="header">ABILITIES</h3>
                  <p>{animal.abilities}</p>
                </div>
                <div
                  className="separator-line"
                  style={{
                    borderColor: `${animal.value.borderColor}`,
                  }}
                ></div>
                <div className="section">
                  <h3 className="header">BEHAVIOUR</h3>
                  <ul>
                    {animal.behaviours.map((behaviour) => (
                      <li key={behaviour}>{behaviour}</li>
                    ))}
                  </ul>
                </div>
                <div
                  className="separator-line"
                  style={{
                    borderColor: `${animal.value.borderColor}`,
                  }}
                ></div>
                <div className="section">
                  <h3 className="header">TRAITS</h3>
                  <ul>
                    {animal.traits.map((trait) => (
                      <li key={trait}>{trait}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* bar graph */}
          <div className="graph-box">
            <div className="graph-labels">
              <p>HERE FOR EACH OTHER</p>

              <p>OWN OUR EXPERIENCE</p>

              <p>ONE TEAM</p>

              <p>DEDICATED LEARNER</p>
            </div>
            <ul className="graph-container">
              <li
                className="graph-item"
                style={{
                  gridColumnEnd: "span " + (result.here_for),
                  gridColumnStart: "1",
                  background: "#4D78D1",
                }}
              >
                {/* <span>{result.here_for} %</span> */}
              </li>
              <li
                className="graph-item"
                style={{
                  gridColumnEnd: "span " + result.own_our,
                  gridColumnStart: "1",
                  background: "#6FD2C8",
                }}
              >
                {/* <span>{result.own_our} %</span> */}
              </li>

              <li
                className="graph-item"
                style={{
                  gridColumnEnd: "span " + result.one_team,
                  gridColumnStart: "1",
                  background: "#E8C351",
                }}
              ></li>

              <li
                className="graph-item"
                style={{
                  gridColumnEnd: "span " + result.learners,
                  gridColumnStart: "1",
                  background: "#9FC284",
                }}
              ></li>
            </ul>

            {/* <div className="graph-labels">
              <p>{result.here_for} %</p>

              <p>{result.own_our} %</p>

              <p>{result.one_team} %</p>

              <p>{result.learners} %</p>

            </div> */}
          </div>

          {/* bottom boxes */}

          {continued ? (
            <div className="bottom-boxes-container">
              {/* only show boxes that are not the current animal value */}

              {values.map(
                (value) =>
                  value.id !== animal.value.id && (
                    <div
                      className="transparent-bottom-box"
                      style={{
                        borderColor: `${value.borderColor}`,
                      }}
                    >
                      <div className="section">
                        <h4>{value.fullName}</h4>
                        <div
                          className="separator-line"
                          style={{
                            borderBottom: "2px solid",
                            borderColor: `${value.borderColor}`,
                            width: "100%",
                            marginBottom: "5px",
                            marginTop: "8px",
                            marginLeft: "auto",
                          }}
                        ></div>

                        <p>{value.motto}</p>
                      </div>
                    </div>
                  )
              )}
              <div
                className="button-group"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                  minWidth: "fit-content",
                  marginLeft: "1em",
                }}>

                <a className="next-button" href={"/posters/" + animal.backgroundName + ".pdf"} download={animal.name + " Posters" + ".pdf"} >
                  Desk Posters
                </a>
                <a className="next-button" href={"/backgrounds/" + animal.backgroundName + ".png"} download={animal.name + " Wallpaper" + ".png"} >
                  Desktop Wallpaper
                </a>
                <a className="next-button" href={"/mobile/" + animal.backgroundName + ".png"} download={animal.name + " Mobile" + ".png"} >
                  Mobile Wallpaper
                </a>
                <a className="next-button" href={"/webex/" + animal.backgroundName + ".png"} download={animal.name + " Webex" + ".png"} >
                  WebEx Background
                </a>
              </div>
            </div>

          ) : (
            <button
              className="next-button"
              style={{
                marginTop: "20px",

              }}
              onClick={() => setContinued(true)}
            >
              Continue
            </button>
          )}
          {/* <p>Here for: {result.here_for}</p>
          <p>Own our: {result.own_our}</p>
          <p>One team: {result.one_team}</p>
          <p>Learners: {result.learners}</p> */}
        </div>


      </div>
    </div>
  );
}

export default Results;
