const here_for = {
  name: "Here for each other",
  fullName: "WE ARE HERE FOR EACH OTHER",
  motto:
    "We build relationships based on genuine support and care. We respect each other as individuals with different identities, experiences and views.",
  borderColor: "#4D78D1",
  backgroundColor: "#4d79d168",
  id: "here_for",
};

let own_our = {
  name: "Own our experience",
  fullName: "WE OWN OUR EXPERIENCE",
  motto:
    "We create a culture where everyone can safely ask questions, share ideas, admit mistakes, and change. We are committed to open communication, active listening and taking action at every level of the organization.",
  borderColor: "#6FD2C8",
  backgroundColor: "#6FD2C868",
  id: "own_our",
};

let one_team = {
  name: "One team",
  fullName: "WE ARE ONE TEAM",
  motto:
    "We all play a valuable role in the success of our organization. We explore ways to reach across our teams and divisions to support each other and meet the needs of our customers, partners, and communities.",
  borderColor: "#E8C351",
  backgroundColor: "rgb(139 119 57 / 41%)",
  id: "one_team",
};

let learner = {
  name: "Dedicated learner",
  fullName: "WE ARE DEDICATED LEARNERS",
  motto:
    "We build expertise through ongoing mentorship, education and varied experiences. We prioritize opportunities to develop and share our skills and knowledge to help each other and the organization reach our potential.",
  borderColor: "#9FC284",
  backgroundColor: "#9FC28468",
  id: "learner",
};

export const values = [here_for, own_our, one_team, learner];

const wolf = {
  name: "Friendly Wolf",
  value: here_for,
  abilities:
    "Wolves are excellent at working in teams, cooperating, collaborating, and supporting each other. Guided by strong leaders, wolves are excellent communicators using body language, vocalisations, and other forms of communications to build relationships and resolve conflicts.",
  behaviour1:
    "Responsible for the well-being of the pack as a whole, including young pups and injured or sick pack members",
  behaviour2:
    "Highly adaptable adjusting behaviour to suit changing conditions",
  behaviour3: "Playful and social",
  behaviours: [
    "Responsible for the well-being of the pack as a whole, including young pups and injured or sick pack members",
    "Highly adaptable adjusting behaviour to suit changing conditions",
    "Playful and social",
  ],
  trait1: "Amplify existing team",
  trait2: "Appreciation for each other's work",
  trait3: "Help each other learn and grow",
  trait4: "Help others feel pride and confidence",
  traits: [
    "Amplify existing team",
    "Appreciation for each other's work",
    "Help each other learn and grow",
    "Help others feel pride and confidence",
  ],
  background: "/backgrounds/wolf.png",
  backgroundName: "wolf",
  color: "#E8C351",
  slogan: "Kind, supporting and respectful you help colleagues feel appreciated."

};

const orca = {
  name: "Honest Orca",
  value: here_for,
  abilities:
    "Orcas are highly intelligent and social creatures. They have a strong sense of family and work together in pods to find food and care for each other. Orcas have excellent communication skills and use a mix of clicks, whistles and body language to coordinate their activities.",
  behaviour1:
    "Devoted to their families and work together to protect and care for each other, especially the more vulnerable pod members",
  behaviour2: "Highly adaptable adjusting to different environments",
  behaviour3:
    "Curious and playful, frequently engaging in social activities and games",
  behaviours: [
    "Devoted to their families and work together to protect and care for each other, especially the more vulnerable pod members",
    "Highly adaptable adjusting to different environments",
    "Curious and playful, frequently engaging in social activities and games",
  ],
  trait1: "Playful and curious",
  trait2: "Strong sense of family and community",
  trait3: "Adaptability and resourcefulness",
  trait4: "Effective communication skills",
  traits: [
    "Playful and curious",
    "Strong sense of family and community",
    "Adaptability and resourcefulness",
    "Effective communication skills",
  ],
  background: "/backgrounds/orca.png",
  backgroundName: "orca",
  color: "#6FD2C8",
  slogan: "Encouraging, focused and considerate you work well with colleagues."
};

const elk = {
  name: "Learned Elk",
  value: here_for,
  abilities:
    "Elk are alert and social creatures that have a natural ability to work together to achieve common goals. They have a strong sense of respect and work hard to look after the interests of the group.",
  behaviour1:
    "Devoted to their herd they work together to protect and care for each other, they will stand their ground when they have to",
  behaviour2:
    "Sensitive to the needs of the group. Supporting those that need additional help",
  behaviour3: "Able to work together to achieve common goals",
  behaviours: ["Devoted to their herd they work together to protect and care for each other, they will stand their ground when they have to", "Sensitive to the needs of the group. Supporting those that need additional help", "Able to work together to achieve common goals"],
  trait1: "Collaboration and teamwork",
  trait2: "Goal orientated with a clear purpose",
  trait3: "Ability to pivot and change direction",
  trait4: "Strong sense of community and respect for authority",
  traits: [
    "Collaboration and teamwork",
    "Goal orientated with a clear purpose",
    "Ability to pivot and change direction",
    "Strong sense of community and respect for authority",
  ],
  background: "/backgrounds/elk.png",
  backgroundName: "elk",
  color: "#9FC284",
  slogan: "Collaborative, agile and focused on what your team needs, you help achieve common goals."
};

const eagle = {
  name: "Understanding Eagle",
  value: own_our,
  abilities:
    "Eagles are powerful and agile creatures that possess exceptional vision and the ability to soar to great heights. They are able to find food and navigate in a variety of environments and conditions, and they possess keen problem-solving skills.",
  behaviour1:
    "Independent and determined creature that possesses a unique ability to view things from the big picture",
  behaviour2: "Highly observant and able to quickly assess situations",
  behaviour3: "Persistent in their pursuit of goals",
  behaviours: ["Independent and determined creature that possesses a unique ability to view things from the big picture", "Highly observant and able to quickly assess situations", "Persistent in their pursuit of goals"],
  trait1: "Keen problem-solving skills",
  trait2: "Highly observant and able to quickly assess situations",
  trait3: "Strong attention to detail",
  traits: [
    "Keen problem-solving skills",
    "Highly observant and able to quickly assess situations",
    "Strong attention to detail",
  ],
  color: "#E8C351",
  background: "/backgrounds/eagle.png",
  backgroundName: "eagle",
  slogan: "Determined, highly observant and powerful you are an effective leader."
};

const bear = {
  name: "Cultured Bear",
  value: own_our,
  abilities:
    "Bears are highly independent creatures that are also capable of working well with others. They thrive in a variety of environments, and they possess great physical strength and resilience.",
  behaviour1:
    "Exceptionally strong and independent creatures that use keen sensory skills to seek out opportunities",
  behaviour2: "Determined and persevering",
  behaviour3: "Self-reliant, yet able to work together when necessary",
  behaviours: ["Exceptionally strong and independent creatures that use keen sensory skills to seek out opportunities", "Determined and persevering", "Self-reliant, yet able to work together when necessary"],
  trait1: "Adaptability and flexibility",
  trait2: "Independant thinker",
  trait3: "Use strength to protect family",
  trait4: "Resilience and perseverance",
  traits: [
    "Adaptability and flexibility",
    "Independant thinker",
    "Use strength to protect family",
    "Resilience and perseverance",
  ],
  background: "/backgrounds/bear.png",
  backgroundName: "bear",
  color: "#9FC284",
  slogan: "Independent, resilient and resourceful you are an effective leader."
};

const lion = {
  name: "Respectful Lion",
  value: own_our,
  abilities:
    "Mountain lions possess great physical strength, speed, and agility. They are able to adapt to different environments and conditions, and they possess a strong sense of focus and determination.",
  behaviour1:
    "Highly adaptive and intelligent creatures that adjust to solve problems as they occur, never losing focus of the goal",
  behaviour2: "Quick to act when necessary",
  behaviour3: "Independent and self-reliant",
  behaviours: ["Highly adaptive and intelligent creatures that adjust to solve problems as they occur, never losing focus of the goal", "Quick to act when necessary", "Independent and self-reliant"],
  trait1: "Focus and determination",
  trait2: "Independent thinking",
  trait3: "Resourcefulness",
  trait4: "Calculated and goal focused",
  traits: [
    "Focus and determination",
    "Independent thinking",
    "Resourcefulness",
    "Calculated and goal focused",
  ],
  background: "/backgrounds/lion.png",
  backgroundName: "lion",
  color: "#4D78D1",
  slogan: "Calculated, goal focused and independent you are a skilled member of the team."
};
const rabbit = {
  name: "Clever Rabbit",
  value: one_team,
  abilities:
    "Rabbits are highly collaborative and productive animals that have a natural ability to work together to achieve group goals. They work hard to protect the group.",
  behaviour1:
    "Devoted to their warren, bravely working together to protect and care for the collective interests",
  behaviour2:
    "Sensitive to the needs of the group. Supporting those that need additional help",
  behaviour3: "Able to work together to achieve common goals",
  behaviours: ["Devoted to their warren, bravely working together to protect and care for the collective interests", "Sensitive to the needs of the group. Supporting those that need additional help", "Able to work together to achieve common goals"],
  trait1: "Resilient and persevering",
  trait2: "Ability to recover from setbacks quickly",
  trait3: "Strong sense of community engagement",
  trait4: "Strong sense of community",
  traits: [
    "Resilient and persevering",
    "Ability to recover from setbacks quickly",
    "Strong sense of community engagement",
    "Strong sense of community",
  ],
  background: "/backgrounds/rabbit.png",
  backgroundName: "rabbit",
  color: "#9FC284",
  slogan: "Determined, productive and community minded you help guide your team."
};

const bee = {
  name: "Supportive Bee",
  value: one_team,
  abilities:
    "Bees are highly organised and efficient creatures that work together in complex societies to achieve greater goals. They communicate with each other through intricate dance patterns and pheromones, and have a strong sense of duty.",
  behaviour1:
    "Devoted to the Queen the group works efficiently, dividing groups to protect and care for the hive",
  behaviour2: "Highly efficient and productive in their daily activities",
  behaviour3: "Adept problem solvers, adapting quickly to their environment",
  behaviours: ["Devoted to the Queen the group works efficiently, dividing groups to protect and care for the hive", "Highly efficient and productive in their daily activities", "Adept problem solvers, adapting quickly to their environment"],
  trait1: "Highly productive, goal focused",
  trait2: "Strong sense of unity and teamwork",
  trait3: "Navigate unexpected challenges",
  trait4: "Communicate complex information and make collective decisions",
  traits: [
    "Highly productive, goal focused",
    "Strong sense of unity and teamwork",
    "Navigate unexpected challenges",
    "Communicate complex information and make collective decisions",
  ],
  background: "/backgrounds/bee.png",
  backgroundName: "bee",
  color: "#4D78D1",
  slogan: "Hardworking, organised and community minded you help achieve bigger goals."

};

const goose = {
  name: "Approachable Goose",
  value: one_team,
  abilities:
    "Geese are social creatures that have a natural ability to work together in flocks to achieve common goals. They communicate with each other through a variety of vocalisations, body language, and flying patterns, and have a strong sense of teamwork.",
  behaviour1:
    "A strong sense of community that inspires team members to work together towards common goals",
  behaviour2: "Not easily deterred, goal focused",
  behaviour3: "Persistent problem solvers",
  behaviours: ["A strong sense of community that inspires team members to work together towards common goals", "Not easily deterred, goal focused", "Persistent problem solvers"],
  trait1: "Determined, goal focused",
  trait2: "Strong sense of teamwork",
  trait3: "Navigate unexpected challenges",
  traits: [
    "Determined, goal focused",
    "Strong sense of teamwork",
    "Navigate unexpected challenges",
  ],
  background: "/backgrounds/goose.png",
  backgroundName: "goose",
  color: "#6FD2C8",
  slogan: "Focused, determined and community minded you help navigate unexpected challenges."
};

const octopus = {
  name: "Kindly Octopus",
  value: learner,
  abilities:
    "Octopuses are intelligent creatures that possess remarkable problem-solving skills and the ability to camouflage themselves in their environment. They possess keen sensory perception and are able to navigate complex environments.",
  behaviour1:
    "Extremely intelligent creatures that can adapt seamlessly into new environments and collaborate effectively with others",
  behaviour2: "Intelligent response to avoid risks",
  behaviour3: "Extremely adaptive",
  behaviours: ["Extremely intelligent creatures that can adapt seamlessly into new environments and collaborate effectively with others", "Intelligent response to avoid risks", "Extremely adaptive"],
  trait1: "Strong problem solver",
  trait2: "Attention to detail",
  trait3: "Innovative thinker",
  trait4: "Highly flexible",
  traits: [
    "Strong problem solver",
    "Attention to detail",
    "Innovative thinker",
    "Highly flexible",
  ],
  background: "/backgrounds/octopus.png",
  backgroundName: "octopus",
  color: "#4D78D1",
  slogan: "Flexible, innovative and detail focused you are a valued asset to any team."
};

const dolphin = {
  name: "Responsible Dolphin",
  value: learner,
  abilities:
    "Dolphins are intelligent and social creatures that possess remarkable communication skills and the ability to navigate and communicate underwater. They are able to work collaboratively in groups, and they possess keen sensory perception.",
  behaviour1:
    "Extremely intelligent  and social creatures that communicate with clarity and collaborate effectively with others",
  behaviour2: "Able to navigate and communicate underwater",
  behaviour3: "Social and creative",
  behaviours: ["Extremely intelligent  and social creatures that communicate with clarity and collaborate effectively with others", "Able to navigate and communicate underwater", "Social and creative"],
  trait1: "Strong communicator",
  trait2: "Collaborative and team oriented",
  trait3: "Innovative, problem solver",
  trait4: "Curious and adventurous",
  traits: [
    "Strong communicator",
    "Collaborative and team oriented",
    "Innovative, problem solver",
    "Curious and adventurous",
  ],
  background: "/backgrounds/dolphin.png",
  backgroundName: "dolphin",
  color: "#6FD2C8",
  slogan: "Social, agile and curious you are an excellent problem solver."
};

const owl = {
  name: "Empathetic Owl",
  value: learner,
  abilities: "Owls possess exceptional sensory perception and the ability to navigate in many  environments. They are able to find food and achieve their goals with great precision, and they possess strong problem-solving skills.",
  behaviour1: "Highly observant, detail-oriented creatures that are quick to assess problems and solve with precision",
  behaviour2: "Alert to environment and opportunities",
  behaviour3: "Quick to act",
  behaviours: ["Highly observant, detail-oriented creatures that are quick to assess problems and solve with precision", "Alert to environment and opportunities", "Quick to act"],
  trait1: "Flexible and perceptive",
  trait2: "Patient and methodical",
  trait3: "Strong problem solving skills",
  trait4: "Attention to detail",
  traits: ["Flexible and perceptive", "Patient and methodical", "Strong problem solving skills", "Attention to detail"],
  background: "/backgrounds/owl.png",
  backgroundName: "owl",
  color: "#E8C351",
  slogan: "Social, agile and curious you are an excellent problem solver."

};



export const resultsData = [wolf, orca, elk, eagle, bear, lion, rabbit, bee, goose, octopus, dolphin, owl];
