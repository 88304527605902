
const answers = ["Strongly disagree", "Disagree", "Disagree slightly", "Neutral", "Agree slightly", "Agree somehwat", "Strongly Agree"];

export const questions = [
  {
    question: "I am empathetic, warm, and often reach out to help others.",
    answers: answers,
    category: "here_for",
    reverse: false,
    answer: '',
  },
  {
    question: "I am skeptical of differing views and tend to prefer my own.",
    answers: answers,
    category: "here_for",
    reverse: true,
    answer: '',
  },
  {
    question: "I believe that kindness and respect are the foundation of strong relationships.",
    answers: answers,
    category: "here_for",
    reverse: false,
    answer: '',
  },
  {
    question: "I like to seek out different points of view and actively listen to others.",
    answers: answers,
    category: "own_our",
    reverse: false,
    answer: '',
  },
  {
    question: "I value working on my own and find it difficult to trust others.",
    answers: answers,
    category: "own_our",
    reverse: true,
    answer: '',
  },
  {
    question: "I don't often admit mistakes or seek out input on my work.",
    answers: answers,
    category: "own_our",
    reverse: true,
    answer: '',
  },
  {
    question: "I am very comfortable reaching across teams to help people feel connected.",
    answers: answers,
    category: "one_team",
    reverse: false,
    answer: '',
  },
  {
    question: "I prefer to work on my own without being distracted by others.",
    answers: answers,
    category: "one_team",
    reverse: true,
    answer: '',
  },
  {
    question: "I believe that we can achieve more together than we can on our own.",
    answers: answers,
    category: "one_team",
    reverse: false,
    answer: '',
  },
  {
    question: "I like new challenges in my work, growing professionally through ongoing training, coaching, and mentorship.",
    answers: answers,
    category: "learners",
    reverse: false,
    answer: '',
  },
  {
    question: "I prefer to focus on my core work and can learn new skills when necessary.",
    answers: answers,
    category: "learners",
    reverse: true,
    answer: '',
  },
  {
    question: "I believe that having strong individual knowledge and skills can help lead and enable our collective growth.",
    answers: answers,
    category: "learners",
    reverse: false,
    answer: '',
  }
];