import React from 'react';
import { useState } from 'react';
import './Choices.css';


function RadioBoxGroup({ onSelectionChange, selectedAnswer, question, currentQuestion }) {


  return (
    <div key={currentQuestion} className='question-container'>


      <div className='question-row'>
        <p className='question-number'>{currentQuestion + 1}. &nbsp; &nbsp;</p>
        <p className='question-title'>{question}</p>
      </div>

      <div className='radio-buttons'>
        <div className='radio-item'>
          <label>
            <input className='radio-question' type="radio" name={"choice"} value={7} onChange={(e) => { onSelectionChange("Strongly agree") }} checked={selectedAnswer == 'Strongly agree'} />
            Strongly Agree
          </label>
        </div>

        <div className='radio-item'>
          <label>
            <input className='radio-question' type="radio" name={"choice"} value={6} onChange={(e) => { onSelectionChange("Agree") }} checked={selectedAnswer == 'Agree'} />
            Agree
          </label>
        </div>

        <div className='radio-item'>
          <label>
            <input className='radio-question' type="radio" name={"choice"} value={5} onChange={(e) => { onSelectionChange("Agree slightly") }} checked={selectedAnswer == 'Agree slightly'} />
            Agree slightly
          </label>
        </div>


        <div className='radio-item'>
          <label>
            <input className='radio-question' type="radio" name={"choice"} value={4} onChange={(e) => { onSelectionChange("Neutral") }} checked={selectedAnswer == 'Neutral'} />
            Neutral
          </label>
        </div>


        <div className='radio-item'>
          <label>
            <input className='radio-question' type="radio" name={"choice"} value={3} onChange={(e) => { onSelectionChange("Disagree slightly") }} checked={selectedAnswer == 'Disagree slightly'} />
            Disagree slightly
          </label>
        </div>


        <div className='radio-item'>
          <label>
            <input className='radio-question' type="radio" name={"choice"} value={2} onChange={(e) => { onSelectionChange("Disagree") }} checked={selectedAnswer == 'Disagree'} />
            Disagree
          </label>
        </div>


        <div className='radio-item'>
          <label>
            <input className='radio-question' type="radio" name={"choice"} value={1} onChange={(e) => { onSelectionChange("Strongly disagree") }} checked={selectedAnswer == 'Strongly disagree'} />
            Strongly disagree
          </label>
        </div>
      </div>
    </div>
  );
}

export default RadioBoxGroup;