import BCA_logo from "./assets/BCA_logo.png";
import "./App.css";
import { questions } from "./questions.js";
import { useState } from "react";
import RadioBoxGroup from "./components/RadioChoices/Choices";
import Results from "./components/Results/Results";
import { useNavigate } from "react-router-dom";

//shuffle questions
// for (i = questions.length - 1; i > 0; i--) {
//   j = Math.floor(Math.random() * i)
//   k = questions[i]
//   questions[i] = questions[j]
//   questions[j] = k
// }

function App() {


  const navigate = useNavigate();

  const choices = [
    "Strongly disagree",
    "Disagree",
    "Disagree slightly",
    "Neutral",
    "Agree slightly",
    "Agree",
    "Strongly agree",
  ];

  choices.reverse();
  const [showResults, setShowResults] = useState(false);

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState("");


  const [result, setResult] = useState({
    here_for: 0,
    own_our: 0,
    one_team: 0,
    learners: 0,
  });

  const [answers, setAnswers] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);

  const { question } = questions[currentQuestion];

  const convertToNumber = (answer) => {
    switch (answer) {
      case "Strongly disagree":
        return 1;
      case "Disagree":
        return 2;
      case "Disagree slightly":
        return 3;
      case "Neutral":
        return 4;
      case "Agree slightly":
        return 5;
      case "Agree":
        return 6;
      case "Strongly agree":
        return 7;
      default:
        return 0;
    }
  };

  const nextQuestion = () => {
    const nextAnswers = answers.map((answer, index) => {
      if (index === currentQuestion) {
        return selectedAnswer;
      }
      return answer;
    });

    setAnswers(nextAnswers);

    if (selectedAnswer === "") {
      alert("Please select an answer");
      return;
    }
    if (questions[currentQuestion].reverse !== true) {
      setResult({
        ...result,
        [questions[currentQuestion].category]:
          result[questions[currentQuestion].category] +
          convertToNumber(selectedAnswer),
      });
    } else {
      setResult({
        ...result,
        [questions[currentQuestion].category]:
          result[questions[currentQuestion].category] +
          (choices.length - convertToNumber(selectedAnswer)),
      });
    }

    if (currentQuestion !== questions.length - 1) {
      setSelectedAnswer(answers[currentQuestion + 1]);
      setCurrentQuestion((currentQuestion) => currentQuestion + 1);
    }


    else {
      setShowResults(true);
    }

  };

  const prevQuestion = () => {

    if (currentQuestion === 0) {


      navigate("/");

      return;

    } else {

      setSelectedAnswer(answers[currentQuestion - 1]);
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  return (
    <div className="quiz-container">
      {!showResults ? (
        <div className="question-bg">
          {/* <RadioBoxGroup name="agreement_level" /> */}

          <div className="headers">
            <h1 className="title">BC ASSESSMENT VALUES QUIZ</h1>

            <img src={BCA_logo} className="logo" alt="logo" />
          </div>

          <RadioBoxGroup
            onSelectionChange={setSelectedAnswer}
            selectedAnswer={selectedAnswer}
            question={question}
            currentQuestion={currentQuestion}
          />

          <div className="buttons-container">
            <button className="back-button" onClick={prevQuestion}>
              &larr; BACK
            </button>
            <button className="next-button" onClick={nextQuestion}>
              {currentQuestion === questions.length - 1
                ? "FINISH"
                : "NEXT QUESTION"} &rarr;
            </button>
          </div>
          <div className="question-progress-bar">
            {questions.map((question, index) => (
              <div className="progress-chunk" key={index} style={
                index <= currentQuestion ? { backgroundColor: "#bdbdbd" } : { backgroundColor: "#000000" }
              }
              >
              </div>
            ))

            }

          </div>
        </div>
      ) : (
        <div>
          <Results result={result} />
          {/* <h1> results</h1>

          <p>Here for: {result.here_for}</p>
          <p>Own our: {result.own_our}</p>
          <p>One team: {result.one_team}</p>
          <p>Learners: {result.learners}</p> */}
        </div>
      )}
    </div>
  );
}

export default App;
