import React from "react";
import "./Landing.css"
import BCA_logo from "../assets/BCA_logo.png";

function Landing() {
  return (

    <div className="landing-container">


      <div className="landing-title">
        <h1 style={
          {
            paddingBottom: "40px",
          }} > BC ASSESSMENT VALUES QUIZ</h1>
      </div>

      <div className="text-container">
        <h2>Which value do you align with? </h2>
        <h3> Deep in British Columbia, surrounded by a forest of numbers and market variables is BC Assessment. Our ecosystem is supported with new values to continue our story of evolution and collective triumph.</h3>
        <h3> <span>Take the quiz,</span> learn more about your connection to the values, and discover which values avatar best aligns with you.</h3>

        <div className="next-button" style={
          {
            maxWidth: "250px",
            marginTop: "40px",

          }}>
          <a href="/quiz">TAKE THE QUIZ</a>

        </div>

        <p style={{ marginTop: "4em" }}>Note: In no way is the Quiz meant to represent a “Personality Test”. Results are meant to show alignment to and engagement with the BCA Corporate Values. Responses or results from participating in the Quiz will not be captured or recorded in any manner.</p>

        <img src={BCA_logo} className="landing-logo" alt="logo" />
      </div>
    </div >


  )
}
export default Landing;